body {
  overscroll-behavior: none;
  background-color: #1d364c !important;
  font-family: "Open Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
}

a {
  cursor: pointer;
}

.button {
  cursor: pointer;
}

.back {
  float: left;
}

.header-menu {
  min-height: 48px;
  width: 50%;
  min-width: 1000px;
  background-color: white;
  padding: 1em;
  margin: 0 auto;
  margin-bottom: -2em;
  margin-top: 3em;
  border-radius: 2px;
}

.header-menu a {
  cursor: pointer;
  text-decoration: none;
}

.row {
  display: flex;
  justify-content: space-between;
}

.container {
  /* FIXME: breaks position: sticky; of child elements */
  /* overflow: scroll; */
  width: 50%;
  min-width: 1000px;
  background-color: white;
  padding: 3em;
  padding-bottom: 5em;
  margin: 0 auto;
  margin-bottom: 3em;
  margin-top: 3em;
  border-radius: 2px;
  /* box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24); */
}

.header {
  color: rgba(0, 0, 0, 0.8);
}

input[type="text"]:not(.choices__input),
textarea,
select {
  border: 1px solid rgba(0, 0, 0, 0.2);
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
  outline: none;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 0 12px;
  height: 40px;
  width: 100%;
}
.rs-picker-search-input input[type="text"] {
  border: none;
}

.choices__input,
.choices__inner {
  background-color: white;
}

textarea {
  resize: vertical;
  height: 100px;
  min-height: 40px;
}

button[type="submit"] {
  cursor: pointer;
  width: auto;
  font: 400 14px system-ui;
  float: right;
  border-color: transparent;
  min-height: 40px;
  padding: 0 23px;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  text-align: center;
  background-color: #4488ff;
  color: #ffffff;
  box-shadow: none;
  outline: none;
  border: 1px solid;
  border-radius: 2px;
  transition: all 0.2s ease-out;
}

button[type="submit"]:disabled {
  cursor: not-allowed;
  pointer-events: none;
  background-color: #8d8f91 !important;
}

button[type="submit"]:hover {
  cursor: pointer;
  width: auto;
  color: white;
  font: 400 14px system-ui;
  float: right;
  border-color: transparent;
  min-height: 40px;
  padding: 0 23px;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  text-align: center;
  background-color: #69a0ff;
  border: 1px solid;
  box-shadow: none;
  outline: none;
  border-radius: 2px;
}

button.cancel {
  cursor: pointer;
  background-color: #e7e7e7 !important;
  color: black !important;
  border-color: transparent !important;
  /* margin-right: 10px; */
}
button.cancel:hover {
  background-color: #cecece !important;
}

.description {
  margin-bottom: 1em;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.66666667;
  color: rgba(0, 0, 0, 0.56);
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.description a {
  color: #4488ff !important;
}

.form-description {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.57142857;
  color: rgba(0, 0, 0, 0.9);
}

.links-container {
  padding-left: 5em;
  padding-right: 5em;
}

.links-container h2 {
  text-align: center;
  margin-bottom: 1em;
}

.links-container ul {
  padding-left: 0;
}

.link {
  background-color: white;
  list-style-type: none;
  padding: 1em;
  margin-bottom: 1rem;
  border-color: #e7e7e7;
  border-style: solid;
  border-width: 1px;
  /* box-shadow: 0 14px 28px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.1); */
  border-radius: 2px;
  transition: 0.3s;
  animation-delay: 2s;
  animation: lian 1s;
}
@keyframes lian {
  from {
    margin-bottom: 0;
  }
  to {
    margin-bottom: 1rem;
  }
}

.link a {
  color: #4488ff;
  text-decoration: none;
}
.link a:hover {
  color: #69a0ff;
  text-decoration: none;
}

.link:hover {
  /* box-shadow: 0 14px 28px rgba(0, 0, 0, 0.2), 0 10px 10px rgba(0, 0, 0, 0.2); */
  border-style: solid;
  border-width: 1px;
  border-color: #5b84a8;
  transform: scale(1.005);
}

@media only screen and (max-width: 400px) {
  body {
    background-color: white;
  }
  .container {
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    margin-top: 0;
  }
  .links-container {
    padding: 2em;
  }
  .modal-content {
    width: 100% !important;
  }
}

.modal {
  display: none;
  position: fixed;
  /* z-index: 1; */
  padding-top: 300px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  /* text-align: center; */
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 30%;
}

.modal-content h2 {
  font-size: x-large;
}

.edit,
.delete,
.view {
  cursor: pointer;
  float: right;
  margin-left: 10px;
}

.Toastify__toast--default {
  background: #fff;
  color: #aaa;
}
.Toastify__toast--info {
  background: #3498db;
}
.Toastify__toast--success {
  background: #1f87fe !important;
}
.Toastify__toast--warning {
  background: #f1c40f;
}
.Toastify__toast--error {
  background: #e74c3c;
}

.dropdown-values {
  border-collapse: collapse;
  width: 100%;
}

.dropdown-values input {
  font-weight: 400;
}

.dropdown-values th {
  padding-right: 20px;
  text-align: center;
  line-height: 50px;
}

.tag {
  margin-right: 5px;
  border-radius: 2px;
  margin-bottom: 5px;
  background-color: rgba(68, 136, 255, 20%);
}

.folder {
  margin-right: 5px;
  border-radius: 2px;
  background-color: inherit;
  color: #4488ff;
  font-weight: 400;
  font-size: 14px;
}

.error-input {
  border: 1px solid rgba(255, 86, 86, 1) !important;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
}

.counter-container {
  position: relative;
  float: right;
  margin-right: 10px;
}

.counter {
  position: absolute;
  top: -5px;
  right: -6px;

  background-color: rgba(245, 81, 115, 0.9);
  color: #fff;
  border-radius: 3px;
  padding: 1px 3px;
  font: 8px Verdana;
}

.file {
  margin: 10px;
  padding: 10px;
  background-color: #feebe8;
  border-radius: 5px;
}

.err-page {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-size: 50pt;
}

.jexcel {
  width: 100%;
}
.jexcel tr {
  height: 20px;
}

.excelTable {
  margin-top: 10px;
  border-collapse: collapse;
  width: 100%;
}
.excelTable td,
th {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
}
.excelTable tr:nth-child(even) {
  background-color: #dddddd;
}

/* @media (prefers-color-scheme: dark) {
  body {
    background-color: #131313 !important;
  }
  .container, .links-container, .header-menu {
    background-color: #1F1F1F !important;
  }
  .header-menu a {
    color: #E2E2E2 !important;
  }
  .rs-picker-default .rs-btn, .rs-picker-input .rs-btn, .rs-picker-default .rs-picker-toggle, .rs-picker-input .rs-picker-toggle, .rs-picker-input {
    background:  #1F1F1F !important;
  }
  input[type="text"] {
    background:  #1F1F1F !important;
    border-color: white !important;
    color: white !important;
  }
  .rs-tag {
    background:  #373737 !important;
    color: #E2E2E2 !important;
  }
  .rs-picker-menu {
    background:  #2D2D2D !important;
  }
  .rs-check-item-focus {
    background:  #363636 !important;
  }
  .rs-checkbox-checker:hover, .rs-checkbox-checker:focus {
    background:  #363636 !important;
  }
  .container h2, .links-container h2 {
    color: white !important;
  }
  .link {
    background-color: #2E2E2E !important;
  }
  .link:hover {
    background-color: #363636 !important;
  }
  .link a {
    color: white !important;
  }
} */
